import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import upiqr from "upiqr";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import UtiitslPANCardService from "../../../../services/User/UtiitslPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import OrderReviewDialog from "./OrderReviewDialog";
import IncomppleteApplication from "./IncompleteApplication";
import TxnStatus from "./TxnStatus";
import PANStatus from "./PANStatus";

const NsdlPANCard = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const mode = props.mode ? props.mode : props.match.params.mode;

  const [checkbox, setCheckBox] = useState(false);

  const [open, setOpen] = useState(false);

  const [applicationMode, setApplicationMode] = useState(mode ? mode : "");
  const [applicationType, setApplicationType] = useState(false);
  const [category, setCategory] = useState(false);
  const [gender, setGender] = useState(false);
  const [panType, setPanType] = useState(false);

  const formRef = useRef();
  const nameRef = useRef();
  const dobRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();

  const submitHandler = async () => {
    const name = nameRef.current.value;
    // const dob = dobRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;

    if (!applicationType) {
      Swal.fire("Opps!", "Invalid Application Type", "error");
      return;
    }

    if (!category) {
      Swal.fire("Opps!", "Invalid Category", "error");
      return;
    }

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    // if (!dob) {
    //    Swal.fire("Opps!", "Invalid Date of Birth", "error");
    //   return;
    // }

    if (!gender) {
      Swal.fire("Opps!", "Invalid Gender", "error");
      return;
    }

    const patternMobile = /^[6-9]\d{9}$/;
    const mobileResult = patternMobile.test(mobile);

    if (!mobileResult) {
      Swal.fire("Opps!", "Invalid Mobile Number", "error");
      return;
    }

    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const emailResult = pattern.test(email);

    if (!emailResult) {
      Swal.fire("Opps!", "Invalid Email Address", "error");
      return;
    }

    if (!panType) {
      Swal.fire("Opps!", "Invalid PAN Type", "error");
      return;
    }

    if (!checkbox) {
      Swal.fire("Opps!", "Please accept to the Aadhaar Consent", "error");
      return;
    }

    setOpen(true);
  };

  const [value, setValue] = useState(new Date());

  const [userId, setUserId] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    setUserId(user.user_id);

    if (user.subscription_status === false) {
      //history.push("/subscription");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCheckbox = () => {
    if (checkbox) {
      setCheckBox(false);
    } else {
      setCheckBox(true);
    }
  };
  return (
    <React.Fragment>
      <Templete t1="Home" t2={`NSDL PAN Card - ${mode}`}>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">NSDL PAN Card - {mode}</h3>
                  </div>

                  <div className="row justify-content-center m-4">
                    <div className="col-sm-12">
                      <div class="row justify-content-center">
                        <div class="col-md-10">
                          <div class="card-body">
                            <div class="card card-primary">
                              <div class="card-header p-0 border-bottom-0">
                                <ul
                                  class="nav nav-tabs"
                                  id="custom-content-below-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active"
                                      id="custom-content-below-home-tab"
                                      data-toggle="pill"
                                      href="#custom-content-below-home"
                                      role="tab"
                                      aria-controls="custom-content-below-home"
                                      aria-selected="true"
                                    >
                                      PAN Application
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      id="custom-content-below-profile-tab"
                                      data-toggle="pill"
                                      href="#custom-content-below-profile"
                                      role="tab"
                                      aria-controls="custom-content-below-profile"
                                      aria-selected="false"
                                    >
                                      Incomplete Application
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      id="custom-content-below-messages-tab"
                                      data-toggle="pill"
                                      href="#custom-content-below-messages"
                                      role="tab"
                                      aria-controls="custom-content-below-messages"
                                      aria-selected="false"
                                    >
                                      TXN Status
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      id="custom-content-below-settings-tab"
                                      data-toggle="pill"
                                      href="#custom-content-below-settings"
                                      role="tab"
                                      aria-controls="custom-content-below-settings"
                                      aria-selected="false"
                                    >
                                      PAN Status
                                    </a>
                                  </li>

                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      href="https://tin.tin.nsdl.com/pantan/StatusTrack.html"
                                      target="_blank"
                                    >
                                      PAN Status (NSDL)
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              class="tab-content pt-1"
                              id="custom-content-below-tabContent"
                            >
                              <div
                                class="tab-pane fade show active"
                                id="custom-content-below-home"
                                role="tabpanel"
                                aria-labelledby="custom-content-below-home-tab"
                              >
                                <div className="mt-3 mb-3">
                                  <form ref={formRef}>
                                    <div class="row">
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>Application Mode</label>
                                          <select
                                            class="form-control"
                                            onChange={(e) => {
                                              setApplicationMode(
                                                e.target.value
                                              );
                                            }}
                                            disabled={mode ? true : false}
                                          >
                                            <option
                                              value={
                                                applicationMode
                                                  ? applicationMode
                                                  : ""
                                              }
                                            >
                                              {applicationMode
                                                ? applicationMode
                                                : "---Select Application Mode---"}
                                            </option>
                                            <option value="EKYC">E-KYC</option>
                                            <option value="ESIGN">
                                              E-SIGN
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>Application Type</label>
                                          <select
                                            class="form-control"
                                            onChange={(e) => {
                                              setApplicationType(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option value="">
                                              ---Select Application Type---
                                            </option>
                                            <option value="49A">
                                              PAN - Indian Citizen (Form 49A)
                                            </option>
                                            <option value="CR">
                                              Changes or Correction in existing
                                              PAN Data / Reprint of PAN Card (No
                                              changes in existing PAN Data)
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>Category</label>
                                          <select
                                            class="form-control "
                                            onChange={(e) => {
                                              setCategory(e.target.value);
                                            }}
                                          >
                                            <option value="">
                                              ---Select Category---
                                            </option>
                                            <option value="P">
                                              Individual
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>
                                            Name (As in Aadhaar Card)
                                          </label>
                                          <input
                                            type="text"
                                            class="form-control "
                                            placeholder="Name (As in Aadhaar Card)"
                                            ref={nameRef}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>Gender</label>
                                          <select
                                            class="form-control "
                                            onChange={(e) => {
                                              setGender(e.target.value);
                                            }}
                                          >
                                            <option value="">
                                              ---Select Gender---
                                            </option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                            <option value="T">
                                              Transgender
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>Mobile Number</label>
                                          <input
                                            type="text"
                                            class="form-control "
                                            placeholder="Mobile Number"
                                            maxLength="10"
                                            onInput={(e) => {
                                              e.target.value =
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                );
                                            }}
                                            ref={mobileRef}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>Email Address</label>
                                          <input
                                            type="text"
                                            class="form-control "
                                            placeholder="Email Address"
                                            ref={emailRef}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-sm">
                                        <div class="form-group">
                                          <label>PAN Type</label>
                                          <select
                                            class="form-control "
                                            onChange={(e) => {
                                              setPanType(e.target.value);
                                            }}
                                          >
                                            <option value="">
                                              ---Select PAN Type---
                                            </option>
                                            <option value="Y">
                                              Physical PAN Card / e-PAN Card
                                            </option>
                                            <option value="N">
                                              e-PAN Card
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-sm">
                                        <div class="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            onClick={handleCheckbox}
                                          />{" "}
                                          I have no objection in authenticating
                                          myself and fully understand that
                                          information provided by me shall be
                                          used for authenticating my identity
                                          through Aadhaar Authentication System
                                          for the purpose stated above and no
                                          other purpose.
                                        </div>
                                      </div>
                                    </div>

                                    <div class="card-footer mt-4">
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={submitHandler}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        type="reset"
                                        class="btn btn-default float-right"
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="custom-content-below-profile"
                                role="tabpanel"
                                aria-labelledby="custom-content-below-profile-tab"
                              >
                                <IncomppleteApplication />
                              </div>
                              <div
                                class="tab-pane fade"
                                id="custom-content-below-messages"
                                role="tabpanel"
                                aria-labelledby="custom-content-below-messages-tab"
                              >
                                <TxnStatus />
                              </div>
                              <div
                                class="tab-pane fade"
                                id="custom-content-below-settings"
                                role="tabpanel"
                                aria-labelledby="custom-content-below-settings-tab"
                              >
                                <PANStatus />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <OrderReviewDialog
          open={open}
          handleClose={handleClose}
          userId={userId}
          handleOpen={handleOpen}
          formRef={formRef}
          application_mode={open ? applicationMode : ""}
          application_type={open ? applicationType : ""}
          category={open ? category : ""}
          name={open ? nameRef.current.value : ""}
          // dob={open ? dobRef.current.value : ""}
          gender={open ? gender : ""}
          mobile={open ? mobileRef.current.value : ""}
          email={open ? emailRef.current.value : ""}
          pan_type={open ? panType : ""}
          setCheckBox={setCheckBox}
        />
      </Templete>
    </React.Fragment>
  );
};

export default NsdlPANCard;
